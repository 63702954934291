<template>
  <router-view/>
</template>

<script setup>
import { setCookie, getCookie } from "@/utils/cookies";
import { onMounted } from "vue";

onMounted(()=>{
  if(window.location.href.indexOf('?inviter=') > -1) {
    setCookie("user_inviter", window.location.href.split("?inviter=")[1])
  } else {
    // deleteCookie("user_inviter")
  }
  if(getCookie('user_identity')) {
    console.log(getCookie('user_identity'))
  } else {
    window.location.replace(window.location.origin + '/account/')
  }
})
</script>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/init.css'
import './assets/css/icon.css'


// 正式环境清除所有console.log
if (process.env.NODE_ENV === 'production') {
    if (window) {
        window.console.log = function () {}
    }
}

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}

// pinia
import { createPinia } from 'pinia'
const pinia = createPinia()


const app = createApp(App)
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// md预览组件
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';

import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
// 代码高亮
import Prism from 'prismjs';
VMdPreview.use(vuepressTheme, {
    Prism,
    // Hljs: hljs,
});
// 快捷复制插件
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';

// 高亮代码行插件
import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index';
import '@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css';

// 代码行号插件
// import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';


// VMdPreview.use(createLineNumbertPlugin());
VMdPreview.use(createHighlightLinesPlugin());
VMdPreview.use(createCopyCodePlugin());
app.use(ElementPlus)
app.use(VMdPreview);
app.use(pinia)
app.use(router).mount('#app')

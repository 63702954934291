/**
 * 设置ck
 */
export function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(`${name}=`)) {
            return cookie.substring(name.length + 1);
        }
    }
    return null;
}
/**
 * 设置cookie
 * @param {*} cname
 * @param {*} cvalue
 * @param {*} exdays
 */
export function setCookie(cname, cvalue, exdays) {
    var top_domain = location.hostname;
    var parts = top_domain.split('.');
    if (parts.length > 2) {
        // 如果域名包含子域名，则返回最后两个部分（即一级域名）
        top_domain = parts.slice(-2).join('.');
    }
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var domain = "; domain="+top_domain+"; path=/";
    var expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; " + expires + domain;
}


/**
 * 销毁ck
 */
// export function deleteCookie(cookieName) {
//     var top_domain = location.hostname;
//     var parts = top_domain.split('.');
//     if (parts.length > 2) {
//         // 如果域名包含子域名，则返回最后两个部分（即一级域名）
//         top_domain = parts.slice(-2).join('.');
//     }
//     var domain = "; domain=" + top_domain;
//     var path = "; path=/";
//     var expires = "; expires=Thu, 01 Jan 1970 00:00:00 UTC";
//
//     document.cookie = cookieName + "=" + domain + path + expires;
//     console.log(cookieName + "=" + domain + path + expires, 2333)
// }

export function deleteCookie(name){
    document.cookie = name +"=; expires=" + new Date().toUTCString() + "; path=/"
}

export function uuid() {
    var temp_url = URL.createObjectURL(new Blob());
    var uuid = temp_url.toString(); // blob:https://xxx.com/b250d159-e1b6-4a87-9002-885d90033be3
    URL.revokeObjectURL(temp_url);
    return uuid.substr(uuid.lastIndexOf("/") + 1);
}